
























































































































































































































































































.addAndEditData {
  .dSelect {
    .el-form-item__content {
      display: flex;
      .el-select {
        width: 100%;
        margin-right: 10px;
      }
    }
  }
}
